import { useContext, createContext, useState, useCallback } from "react";
import { Alert, Button, CloseButton, Modal, Toast } from "react-bootstrap";

export const NotificationStatus = {
  Primary: "primary",
  Secondary: "secondary",
  Success: "success",
  Danger: "danger",
  Warning: "warning",
  Info: "info",
  Dark: "dark"
}

const NotificationLimit = 5

const NotificationContext = createContext({
  notification: null,
  notificationText: null,
  success: () => {},
  error: () => {}
})

export function useNotificationContext() {
  return useContext(NotificationContext)
}

export const NotificationProvider = (props) => {
  const [toastList, setToastList] = useState([])
  const [alertList, setAlertList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState({})

  const removeToast = (timestamp) => {
    setToastList( tl => tl.filter(t => t.timestamp !== timestamp))
  }

  const clearToasts = useCallback(() => {
    setToastList([])
  }, [])

  const sendToast = useCallback((toast) => {
    if(!toast.message) return

    const timestamp = new Date().getTime()
    const newToast = {
      status: toast.status ?? NotificationStatus.Dark,
      message: toast.message,
      delay: toast.delay ?? 4000,
      timestamp,
      autohide: toast.autohide ?? true,
      dismissible: toast.dismissible ?? (toast.autohide !== undefined ? !toast.autohide : false)
    }

    if(newToast.autohide){
      setTimeout(() => {removeToast(newToast.timestamp)}, newToast.delay)
    }

    setToastList(tl => {
      const list = [...tl, newToast]
      list.splice(0, list.length - NotificationLimit)
      return list
    })
  }, [])

  const removeAlert = useCallback((timestamp) => {
    setAlertList( al => al.filter(a => a.timestamp !== timestamp))
  }, [])

  const clearAlerts = useCallback(() => {
    setAlertList([])
  }, [])

  const sendAlert = useCallback((alert) => {
    if(!alert.message) return

    const timestamp = new Date().getTime()
    const newAlert = {
      status: alert.status ?? NotificationStatus.Dark,
      message: alert.message,
      delay: alert.delay ?? 4000,
      timestamp,
      autohide: alert.autohide ?? true,
      dismissible: alert.dismissible ?? (alert.autohide !== undefined ? !alert.autohide : false)
    }

    if(newAlert.autohide){
      setTimeout(() => {removeAlert(newAlert.timestamp)}, newAlert.delay)
    }

    setAlertList(al => {
      const list = [...al, newAlert]
      list.splice(0, list.length - NotificationLimit)
      return list
    })
  }, [removeAlert])

  const clearNotifications = useCallback(() => {
    clearAlerts()
    clearToasts()
  }, [clearAlerts, clearToasts])

  const sendConfirmModal = useCallback((modal) => {
    if(showModal) return false
    setConfirmModal(modal)
    setShowModal(true)
    return true
  }, [showModal])

  const modalConfirm = useCallback(() => {
    setShowModal(false)
    if(typeof confirmModal.confirm === 'function') {
      confirmModal.confirm()
    }
  }, [confirmModal])

  const modalReject = useCallback(() => {
    setShowModal(false)
    if(typeof confirmModal.reject === 'function') {
      confirmModal.reject()
    }
  }, [confirmModal])

  const textColor = (status) => {
    if([NotificationStatus.Warning, NotificationStatus.Info].includes(status)) {
      return "text-black"
    }
    return "text-white"
  }

  return (
    <NotificationContext.Provider
      value={{
        sendToast, clearToasts, sendAlert, clearAlerts, clearNotifications, sendConfirmModal
      }}
    >
{/* PAGE CONTENT */}

      {props.children}

{/* NOTIFICATION TOASTS AND ALERTS */}

      <div className="position-fixed bottom-0 start-50 translate-middle-x mb-4 d-flex flex-column align-items-center gap-2 w-100 px-3" style={{maxWidth: "40rem", zIndex: 3000}}>
        {toastList.map((toast, i) => (
          <Toast
            key={i}
            bg={toast.status}
            className="w-auto"
          >
            <Toast.Body className={`${textColor(toast.status)} d-flex flex-row align-items-center column-gap-2 justify-content-between`}>
              <span>{toast.message}</span>
              {toast.dismissible && <CloseButton onClick={() => removeToast(toast.timestamp)} />}
            </Toast.Body>
          </Toast>
        ))}
        {alertList.map((alert, i) => (
          <Alert
            key={i}
            variant={alert.status}
            className="mb-0 w-100 d-flex flex-row align-items-center column-gap-2 justify-content-between"
          >
            <span className="w-100">{alert.message}</span>
            {alert.dismissible && <CloseButton onClick={() => removeAlert(alert.timestamp)} />}
          </Alert>
        ))}
      </div>

{/* CONFIRMATION MODAL */}

      <Modal
        style={{zIndex: 2001}}
        backdropClassName="top-z-index"
        show={showModal}
        centered={confirmModal.center === true}
        onHide={() => setShowModal(false)}
      >
        {confirmModal.heading &&
          <Modal.Header closeButton>
            <Modal.Title>{confirmModal.heading}</Modal.Title>
          </Modal.Header>
        }
        <Modal.Body>{confirmModal.body}</Modal.Body>
        <Modal.Footer>
          <Button variant={confirmModal.rejectColor ?? "secondary"} onClick={modalReject}>
            {confirmModal.rejectText ?? "No"}
          </Button>
          <Button variant={confirmModal.confirmColor ?? "primary"} onClick={modalConfirm} disabled={confirmModal.confirmDisabled ?? false}>
            {confirmModal.confirmText ?? "Yes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </NotificationContext.Provider>
  );
};