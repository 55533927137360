import React, {useState, useEffect} from "react";
import { Button, ListGroup} from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { loadFlights, returnFlight } from "../libs/databaseAccess";
import { onError } from "../libs/errorLib";
import "./Home.css";
import "./Launch.css";

export default function Awaiting() {
    const { userID, club, isAuthenticated, tzoffset } = useAppContext();
    const [launchFlight, setLaunchFlight] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [waitingFlights, setWaitingFlights] = useState([]);
    const [launchFlightName, setLaunchFlightName] = useState("");
    //const [launchFlightWeight, setLaunchFlightWeight] = useState(0);
    const [launchFlightType, setLaunchFlightType] = useState("");
    const [launchFlightSpeed, setLaunchFlightSpeed] = useState(0);

    // Build local date string
    const date = new Date();
    date.setTime(date.getTime()-tzoffset*60*1000);
    const localDateString = date.toISOString().slice(0,10);

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated || club === "" || userID === "") {
                return;
            }
            try {
                // Pull club specific info from DynamoDB
                let todaysFlights = await loadFlights(club, localDateString);
                todaysFlights = todaysFlights.filter(a => !a.acceptedAt && !a.launchedAt && !a.offTowAt && !a.landedAt && !a.cancelledAt)
                setWaitingFlights(todaysFlights.sort((a,b) => (a.submittedAt - b.submittedAt)));
            }   catch (e) {
                onError(e);
            }   setIsLoading(false);
        }
        onLoad();
    }, [isAuthenticated, club, userID, isLoading, localDateString]);

    function renderFlights(flights) {
        return (
          <div className="flights">
            <ListGroup>
              { flights.map((flight, i) =>
              <Button variant="outline-secondary" key={flight.SK} value={flight.SK} onClick = {(e) => setLaunchValues(e)} active={flight.SK === launchFlight}>
                  {flight.first+" "+flight.last.slice(0,1)+" in "+flight.glider+", "+flight.launchType/*+", "+getWeight(flight)+" kg"*/}
              </Button>)
              }
            </ListGroup>
          </div>
        );
      }

    function setLaunchValues(event){
        const id = event.target.value;
        const launch = returnFlight(club, id, waitingFlights, localDateString);
        if (launchFlight === id) {
          setLaunchFlight(null);
        } else {
          //const totalWeight = launch.weight+launch.passengerWeight+launch.gliderWeight+launch.waterWeight;
          setLaunchFlight(id);
          setLaunchFlightName(launch.glider);
          //setLaunchFlightWeight(Number.parseFloat(totalWeight).toFixed());
          setLaunchFlightType(launch.launchType);
          setLaunchFlightSpeed(launch.towSpeed);
        }
      }

    // function getWeight(flight) {
    //     let total = 0;
    //     const weights = ["gliderWeight", "weight", "passengerWeight", "waterWeight"];
    //     weights.forEach((item) => {
    //       total += item in flight ? flight[item] : 0;
    //     })
    //     return total;
    // }

    function renderLander() {
        return (
          <div className="lander">
            <h1>Take Up Slack</h1>
            <p>Your Gliding Flight Line Management Tool</p>
            {/* {(club !== "") ? <h2>Welcome to {clubName}!</h2> : <h2>Please sign in </h2>} */}
          </div>
        );
      }

    function renderActivities() {
        return (
            <>
            { launchFlight && 
            <>
            <h2>{launchFlightName} to {launchFlightType} @ {launchFlightSpeed} kn{/*, {launchFlightWeight} kg*/}</h2>
            <hr></hr>
            </>
            }
            {
              <div className="Home"><h4>Awaiting tows: </h4>
                {(renderFlights((waitingFlights)))}    
              </div>
            }
            </>
        );
    }

    return (
        <div className="Home">
          {isAuthenticated ? renderActivities() : renderLander()}
        </div>
      );
}