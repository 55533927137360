import { get, post, put, del } from "aws-amplify/api";
import axios from 'axios';

// Constants
export const RequestType = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

export const ResultType = {
  JSON: 'json',
  TEXT: 'text',
  BLOB: 'blob'
}

//This file requires backend changes in listItem.js, to accommodate loadRefuels

function createAxiosConfig(method, params) {
  const axiosOptions = {
    method,
    url: `${process.env.REACT_APP_DEV_API}${params.path}`
  };
  if(!params.options) return axiosOptions

  const optionsKeys = Object.keys(params.options)
  if(optionsKeys.includes("headers")) {
    axiosOptions.headers = params.options.headers
  }
  if(optionsKeys.includes("queryParams")) {
    axiosOptions.params = params.options.queryParams
  }
  if(optionsKeys.includes("body")) {
    axiosOptions.data = params.options.body
  }
  if(optionsKeys.includes("withCredentials")) {
    axiosOptions.withCredentials = params.options.withCredentials
  }
  return axiosOptions
}

export async function apiRequest(method, params, resultType = ResultType.JSON) {
  if(process.env.REACT_APP_USE_DEV_API === "true" && process.env.REACT_APP_DEV_API){ // Local API Calling
    const config = createAxiosConfig(method, params)
    let response;
    try{
      response = await axios(config)
    } catch(e) {
      if(e.response){
        response = e.response
      } else {
        response = {headers: [], status: 600, data: e.message}
      }
    }
    return {
      headers: response.headers,
      statusCode: response.status,
      body: response.data
    }
  } else { // Production API Calling
    let result = undefined;
    switch(method) {
      case RequestType.GET:
        result = get(params)
        break;
      case RequestType.POST:
        result = post(params)
        break;
      case RequestType.PUT:
        result = put(params)
        break;
      case RequestType.DELETE:
        result = del(params)
        break;
      default:
        return undefined;
    }

    const response = await result.response
    if(response.headers["content-length"] === "0") {
      return {
        headers: response.headers,
        statusCode: response.statusCode,
      }
    }

    let body
    let parsingError
    try{
      switch(resultType) {
        case ResultType.BLOB:
          body = await response.body.blob()
          break;
        case ResultType.TEXT:
          body = await response.body.text()
          break;
        case ResultType.JSON:
        default:
          body = await response.body.json()
          break;
      }
    } catch(e) {
      parsingError = e
    }
    
    return {
      headers: response.headers,
      statusCode: response.statusCode,
      body,
      parsingError
    }
  }
}

export async function checkVersion(version) {
  const options = { 
    queryParams: {
      version: version,
    }};
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/check-version", options});
    return restOperation.body
}

export async function getUserBalance(club, userID) {
  const options = { 
    queryParams: {
      club: club,
      userID: userID,
    }};
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-balance", options});
    return restOperation.body
}

export async function getClubMinimums(club) {
  const options = { 
    queryParams: {
      club: club,
    }};
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-club-minimum", options});
    return restOperation.body
}

export async function getTugs(club) {
  const options = { 
    queryParams: {
      club: club,
    }};
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-tugs", options});
    return restOperation.body
}

export async function loadTransactions(club, startDate, endDate) {
  const options = {
    queryParams: {
      club: club,
      startDate: startDate,
      endDate: endDate,
      balance: "AccountingSummary"
    },
  }
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-balances", options});
  return restOperation
}

export async function loadTaxTransactions(club, taxType, startDate, endDate) {
  const options = {
    queryParams: {
      club,
      taxType,
      startDate,
      endDate,
      balance: "TaxTransactions"
    },
  }
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-balances", options});
  return restOperation
}

export async function loadDepositTransactions(club, transferType, startDate, endDate) {
  const options = {
    queryParams: {
      club,
      transferType,
      startDate,
      endDate,
      balance: "DepositTransactions"
    },
  }
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-balances", options});
  return restOperation
}

export async function loadUserTransactions(club, user, startDate, endDate) {
  const options = {
    queryParams: {
      club,
      user,
      startDate,
      endDate,
    },
  }
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-user-transactions", options});
  return restOperation
}

export async function loadCrewPayload(club, userID) {
  const options = {
    queryParams: {
      club,
      userID
    }
  }
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/serve-crew", options})
  return restOperation
}

export async function loadAdminPayload(club) {
  const options = {
    queryParams: {
      club,
    }
  }
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/serve-admin", options})
  return restOperation
}

export async function getGroundStatus(club, day, includePilots=false) {
  const options = {
    queryParams: {
      club,
      day,
      includePilots
    }
  }
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/ground-status", options})
  return restOperation
}
export async function loadLogsPayload(club, startDate, endDate, includeSetup = false) {
  const options = {
    queryParams: {
      club,
      startDate,
      endDate,
      includeSetup
    }
  }
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/serve-logs", options})
  return restOperation
}

export async function loadPilotTransactions(club, pilotID, startDate, endDate) {
  const options = {
    queryParams: {
      club: club,
      id: pilotID,
      startDate: startDate,
      endDate: endDate,
    },
  }
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-transactions", options});
  return restOperation.body
}

export async function loadRecentPilotTransactions(club, pilotID){
  if (!club || !pilotID) {
    return null;
  }

  const options = {
    queryParams: {
      club: club,
      id: pilotID,
    },
  }
  
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-recent-transactions", options});
  return restOperation.body
}

export async function getUserData(clubId, userID) {
  const options = { 
    queryParams: {
      club: clubId,
      userID: userID,
    }};
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-pilot", options});
    return restOperation.body
}

export async function loadPilotClubs(userID) {
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: `/get-pilot-clubs/${userID}`});
  return restOperation.body
}

export async function loadClubs() {
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/list-clubs"});
  return restOperation.body
}

export async function loadIntroList(club) {
  const restOperation = await apiRequest(RequestType.GET, {
    apiName: "flightline",
    path: "/get-intros",
    options: {queryParams:{"club":club}}
  })
  return restOperation.body
}

export async function loadClubPaymentMethods(club) {
  const restOperation = await apiRequest(RequestType.GET, {
    apiName: "flightline",
    path: "/get-club-payment-methods/",
    options: {queryParams:{"club":club}}
  })
  return restOperation.body
}

export async function updateClubTransactionLock(club, lockDate) {
  const restOperation = await apiRequest(RequestType.POST, {
    apiName: "flightline",
    path: "/update-admin-parameter/",
    options: {
      body:{club, lockDate, parameter: "lockDate"}
    }
  })
  return restOperation
}

export async function addClubFee(club, feeName) {
  const restOperation = await apiRequest(RequestType.POST, {
    apiName: "flightline",
    path: "/update-admin-parameter/",
    options: {
      body:{club, feeName, action: "add", parameter: "clubFees"}
    }
  })
  return restOperation
}
export async function removeClubFee(club, feeName) {
  const restOperation = await apiRequest(RequestType.POST, {
    apiName: "flightline",
    path: "/update-admin-parameter/",
    options: {
      body:{club, feeName, action: "remove", parameter: "clubFees"}
    }
  })
  return restOperation
}

export async function startDay(club) {
  const restOperation = await apiRequest(RequestType.POST, {
    apiName: "flightline", 
    path: "/start-day",
    options: {
      body:{club}
    }
  });
  return restOperation;
}

export async function updateDefaultIntroStalePeriod(club, period) {
  const restOperation = await apiRequest(RequestType.POST, {
    apiName: "flightline",
    path: "/update-admin-parameter/",
    options: {
      body:{club, period, parameter: "introStalePeriod"}
    }
  })
  return restOperation
}

async function loadItem(options) {
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/list-items/", options});
  return restOperation.body
}

export async function loadGliders(club) {
  const options = { 
    queryParams: {
      club: club,
      type: "GLIDER",
    }};
  return loadItem(options);
}

export async function loadTugs(club) {
  const options = { 
    queryParams: {
      club: club,
      type: "TUG",
    }};
  return loadItem(options);
}

export async function loadRefuels(tug) {
  const options = { 
    queryParams: {
      tug: tug,
      type: "REFUEL",
    }};
  return loadItem(options);
}

export async function loadPilots(club) {
  const options = { 
    queryParams: {
      club: club,
      type: "PILOT",
    }};
  return loadItem(options);
}

export async function loadPaymentMethods(club) {
  const options = {
    queryParams: {
      club: club,
    }};
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-club-payment-methods", options});
    return restOperation.body
}

export async function loadLaunches(club) {
  const options = { 
    queryParams: {
      club: club,
      type: "LAUNCH",
    }};
  return loadItem(options);
}

export async function loadIntroLaunches(club) {
  const options = { 
    queryParams: {
      club: club,
      type: "INTRO_LAUNCH",
    }};
  return loadItem(options);
}

export async function loadFlights(club, dayText) {
  const options = { 
    queryParams: {
      club: club, 
      day: dayText,
      type: "FLIGHT",
    }};
  return loadItem(options);
}

export async function loadTows(club){
  const options = await {
    queryParams: {
      club: club,
      type: "TOW"
    }
  };
  return loadItem(options);
}

// Delete items
export async function deleteFlight(club, flight, dayText) {
  const options = { 
    queryParams: {
      club: club, 
      date: dayText,
      object: "FLIGHT",
      item: flight,
    }};
  const restOperation = await apiRequest(RequestType.DELETE, {apiName: "flightline", path: "/delete/", options});
    return restOperation.body
}

export async function deletePilot(club, id) {
  const options = { 
    queryParams: {
      club: club, 
      object: "PILOT",
      item: id,
    }};
  const restOperation = await apiRequest(RequestType.DELETE, {apiName: "flightline", path: "/delete/", options});
    return restOperation.body
}

export async function deleteLaunch(club, id) {
  const options = { 
    queryParams: {
      club: club, 
      object: "LAUNCH",
      item: id,
    }};
  const restOperation = await apiRequest(RequestType.DELETE, {apiName: "flightline", path: "/delete/", options});
    return restOperation.body
}

export async function deleteIntroLaunch(club, id) {
  const options = { 
    queryParams: {
      club: club, 
      object: "INTRO_LAUNCH",
      item: id,
    }};
  const restOperation = await apiRequest(RequestType.DELETE, {apiName: "flightline", path: "/delete/", options});
    return restOperation.body
}

export async function deleteGlider(club, id) {
  const options = { 
    queryParams: {
      club: club, 
      object: "GLIDER",
      item: id,
    }};
  const restOperation = await apiRequest(RequestType.DELETE, {apiName: "flightline", path: "/delete/", options});
    return restOperation.body
}

export async function deleteTug(club, id) {
  const options = { 
    queryParams: {
      club: club, 
      object: "TUG",
      item: id,
    }};
  const restOperation = await apiRequest(RequestType.DELETE, {apiName: "flightline", path: "/delete/", options});
    return restOperation.body
}

export async function loadInstructors(club) {
  const pilots = await loadPilots(club);
  return pilots.filter(pilot => pilot.isInstructor);
}

export async function loadTowPilots(club) {
  const pilots = await loadPilots(club);
  return pilots.filter(pilot => pilot.isTowPilot);
}

export async function getClubInfo(clubId) {
  const options = {
    queryParams: {
      club: clubId
    }
  };
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-club-info", options});
  return restOperation.body
}

export async function getClubMessage(clubId) {
  const options = {
    queryParams: {
      club: clubId
    }
  };
  const restOperation = await apiRequest(RequestType.GET, {apiName: "flightline", path: "/get-club-message", options});
  return restOperation.body
} 

// Utility functions
export function returnFlight(club, id, launches, localDateString){
  const pk = club+"#FLIGHT#"+localDateString;
  return returnItem(pk, id, launches);
}

export function returnLaunch(club, id, launches){
  const pk = club+"#LAUNCH";
  return returnItem(pk, id, launches);
}

export function returnIntroLaunch(club, id, launches){
  const pk = club+"#INTRO_LAUNCH";
  return returnItem(pk, id, launches);
}

export function returnPilot(club, id, pilots){
  const pk = club+"#PILOT";
  return returnItem(pk, id, pilots);
}

export function returnGlider(club, id, gliders){
  const pk = club+"#GLIDER";
  return returnItem(pk, id, gliders);
}

export function returnIntro(club, id, intros){
  const pk = club+"#INTRO";
  return returnItem(pk, id, intros);
}

export function returnTug(club, id, tugs){
  const pk = club+"#TUG";
  return returnItem(pk, id, tugs);
}

export function returnClub(id, clubs){
  const pk = "CLUB";
  return returnItem(pk, id, clubs);
}

// Utility function to return item from list in already retrieved list - avoids another database call
export function returnItem(pk, sk, itemlist){
  for (var item of itemlist) {
    if ((item.PK === pk) && (item.SK === sk)){
      return item;
    }
  }
}


// export async function returnHobbs(club, tug){
//   const tows = await loadTows(club);
//   let tugTows = tows.filter(a => a.tug === tug);
//   tugTows = tugTows.sort((a,b)=>b.date-a.date);
//   const hobbsTime = tugTows.hobbs[0];
//   return hobbsTime;
// }
