import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FormGroup, FormControl, InputGroup, Button, ButtonGroup, FormCheck, ToggleButtonGroup, ToggleButton} from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { RequestType, apiRequest, loadIntroLaunches, loadPaymentMethods } from "../libs/databaseAccess";
import ShortUniqueId from 'short-unique-id';
import "./Test.css";
import { DateTime } from "luxon";
import { MultiSelect } from "react-multi-select-component";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import { formatTaxOptions, toCurrencyString } from "../libs/utils";

export default function Intro(){
  const { userID, club, isAuthenticated, userIsAdmin, clubTimeZone, clubDefaultIntroStalePeriod, clubTaxRates } = useAppContext();
  const { sendAlert, sendToast, sendConfirmModal } = useNotificationContext();

  const [flightTypeList, setFlightTypeList] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [date, setDate] = useState("");
  const [staleOffset, setStaleOffset] = useState(0);
  const [buyerEmail, setBuyerEmail] = useState("");
  const [buyerFirst, setBuyerFirst] = useState("");
  const [buyerLast, setBuyerLast] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientFirst, setRecipientFirst] = useState("");
  const [recipientLast, setRecipientLast] = useState("");
  const [note, setNote] = useState("");
  const [payment, setPayment] = useState("");
  const [paymentRef, setPaymentRef] = useState("");
  const [flightType, setFlightType] = useState("");
  const [introType, setIntroType] = useState("");
  const [price, setPrice] = useState(0);
  const [serialNum, setSerialNum] = useState("");
  const [forSelf, setForSelf] = useState(false);
  const [taxStatus, setTaxStatus] = useState("exempt")
  const [taxList, setTaxList] = useState([])

  const resetDate = useCallback(() => {
    const dateObj = DateTime.fromObject({}, {zone: clubTimeZone});
    var dateStr = dateObj.toISODate();
    setDate(dateStr);
  }, [clubTimeZone])

  const initializeDate = useCallback(() => {
    resetDate()
  }, [resetDate])

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated || club === "" || userID === "") {
        return;
      }
      try {
        initializeDate()
        setStaleOffset(clubDefaultIntroStalePeriod)
        const uid = new ShortUniqueId();
        const launches = await loadIntroLaunches(club);
        const payments = await loadPaymentMethods(club);
        setFlightTypeList(launches);
        setPayment(payments[0] ?? "")
        setPaymentMethods(payments);
        var defaultSerial = uid();
        setSerialNum(defaultSerial);
      }
      catch (e) {
        onError(e);       
      }
    }
    onLoad();
  }, [userID, club, isAuthenticated, userIsAdmin, clubDefaultIntroStalePeriod, initializeDate]);

  function setLaunch(event){
    var launch = flightTypeList.find((a)=>(a.SK === event));
    if(launch !== undefined){
      setFlightType(launch.launch);
      setIntroType(launch.SK);
      setPrice(launch.price);
      setTaxStatus(launch.taxStatus ?? "exempt")
      const taxRates = launch.taxRates ?? []
      setTaxList(taxRates.map((r) => 
        taxOptions.find((o) => o.value === r) ?? {label: "Unknown", value: r}
      ))
    }
  }

  async function submitChanges(){
    sendConfirmModal({
      heading: "Sell intro",
      body: <span>Sell <strong>{introType}</strong> to <strong>{buyerFirst} {buyerLast}</strong> for <strong>{toCurrencyString(price, "CAD", true)}</strong>?</span>,
      confirmText: "Submit",
      confirmColor: "primary",
      rejectText: "Cancel",
      confirm: submitAPICall
    })
  }

  async function submitAPICall(){
    var dateStruct = new Date();
    const recipientNameCache = `${recipientFirst} ${recipientLast}`
    const taxRates = taxList.map((e) => e.value)
    const data = {
      "club":club,
      "time":dateStruct.getTime(),
      "launchType":flightType,
      "price":price,
      "SN":serialNum,
      "buyerEmail":buyerEmail,
      "buyerFirst":buyerFirst,
      "buyerLast":buyerLast,
      "recipientEmail":recipientEmail,
      "recipientFirst":recipientFirst,
      "recipientLast":recipientLast,
      "note":note,
      "payment":payment,
      "paymentRef":paymentRef,
      "date":date,
      "staleDate":staleDate, 
      "flightRef":'',
      "taxStatus": taxStatus,
      "taxRates": taxRates
    }
    const response = await apiRequest(RequestType.POST, {apiName: "flightline", path: "/create-intro/", options: {"body": data}});

    if(response.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: `Failed to submit intro purchase`
      })
    } else {
      sendToast({
        status: NotificationStatus.Success,
        message: `Purchase of Intro for ${recipientNameCache} was successful.`
      })
      resetInfo();
    }

  }

  function resetInfo(){
    const uid = new ShortUniqueId();
    setFlightType("");
    initializeDate()
    setNote("");
    setPayment("");
    setPaymentRef("");
    setSerialNum(uid());
    setBuyerEmail("");
    setBuyerFirst("");
    setBuyerLast("");
    setRecipientEmail("");
    setRecipientFirst("");
    setRecipientLast("");
    setPrice(0);
    setTaxStatus()
    setTaxList([])
    setTaxStatus("exempt")
    setForSelf(false)
    setIntroType("")
    resetDate()
  }

  function setPassengerInfo(){
    setForSelf(!forSelf);
    if(!forSelf){
      setRecipientEmail(buyerEmail);
      setRecipientFirst(buyerFirst);
      setRecipientLast(buyerLast);
    }
  }

  const updateStaleOffset = (newStaleDate) => {
    const purchaseDate = DateTime.fromISO(date, {zone: clubTimeZone})
    const staleDate = DateTime.fromISO(newStaleDate, {zone: clubTimeZone})

    const diff = staleDate.diff(purchaseDate, 'days')
    setStaleOffset(diff.days > 0 ? diff.days : 0)
  }

  const staleDate = useMemo(() => {
    if(date === '') return ''
    const staleDate = DateTime.fromISO(date, {zone: clubTimeZone}).plus({days: staleOffset})
    return staleDate.toISODate()
  }, [clubTimeZone, staleOffset, date])

  const taxOptions = useMemo(() => {
    return formatTaxOptions(clubTaxRates)
  }, [clubTaxRates])
  
  const taxSelectRenderer = (selected) => {
    if(selected.length < 1) return "None";
    let str = "";
    for(const i in selected) {
      str += selected[i].value
      if(i < selected.length - 1) {
        str += ", "
      }
    }
    return str
  }

  const updateBuyerFirst = (value) => {
    setBuyerFirst(value)
    if(forSelf) {
      setRecipientFirst(value)
    }
  }
  const updateBuyerLast = (value) => {
    setBuyerLast(value)
    if(forSelf) {
      setRecipientLast(value)
    }
  }
  const updateBuyerEmail = (value) => {
    setBuyerEmail(value)
    if(forSelf) {
      setRecipientEmail(value)
    }
  }

  return (
    <main className="d-flex flex-column gap-3 pt-3">
      <h2>New Intro Flight</h2>
      <hr className="m-0"/>
      <FormGroup controlId="launchType" size="lg"><h4 style={{display: "inline"}}>Launch Type: </h4>
        <ButtonGroup className="flex-wrap">
          { flightTypeList.map((launch, i) =>
            <Button variant={"outline-primary"} key={launch.SK} value={launch.SK} onClick = {(e) => setLaunch(e.target.value)} active={launch.SK === introType}>
              {launch.SK}
            </Button>)
          }
        </ButtonGroup>    
      </FormGroup>
      <InputGroup>
        <InputGroup.Text>Purchase Price:</InputGroup.Text>
        <FormControl as = "input" 
          type = "number"
          value = {price}
          onChange = {(e)=>{setPrice(e.target.value)}}
        />
      </InputGroup>
      <ToggleButtonGroup  name="taxStatusCheck" type="radio" value={taxStatus} onChange={(e) => setTaxStatus(e)}>
        <InputGroup.Text>Tax Status:</InputGroup.Text>
        <ToggleButton id="exempt" variant="outline-primary" name="taxStatusCheck" value="exempt">Tax Exempt</ToggleButton>
        <ToggleButton id="inclusive" variant="outline-primary" name="taxStatusCheck" value="inclusive">Tax Inclusive</ToggleButton>
        <ToggleButton id="exclusive" variant="outline-primary" name="taxStatusCheck" value="exclusive">Tax Exclusive</ToggleButton>
      </ToggleButtonGroup>
      {taxStatus !== "exempt" && taxStatus !== undefined &&
        <InputGroup>
          <InputGroup.Text>Applicable Taxes:</InputGroup.Text>
          <MultiSelect
            className="flex-grow-1 flex-shrink-1 multi-select"
            disableSearch
            hasSelectAll={false}
            options={taxOptions}
            value={taxList}
            onChange={setTaxList}
            valueRenderer={taxSelectRenderer}
          />
        </InputGroup>
      }
      <InputGroup>
        <InputGroup.Text>Payment Method:</InputGroup.Text>
        <FormControl as = "select" placeholder = "select" value = {payment} onChange = {(e)=>{setPayment(e.target.value)}}>
        {paymentMethods.map((item)=>
            <option key = {item} value = {item}>{item}</option>
        )}
        </FormControl>
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Payment Reference:</InputGroup.Text>
        <FormControl as = "input" 
          type = "text"
          value = {paymentRef}
          onChange = {(e)=>{setPaymentRef(e.target.value)}}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Sold To (First Name):</InputGroup.Text>
        <FormControl as = "input" 
          type = "text"
          value = {buyerFirst}
          onChange = {(e) => updateBuyerFirst(e.target.value)}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Sold To (Last Name):</InputGroup.Text>
        <FormControl as = "input" 
          type = "text"
          value = {buyerLast}
          onChange = {(e) => updateBuyerLast(e.target.value)}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Sold To (Email):</InputGroup.Text>
        <FormControl as = "input" 
          type = "text"
          value = {buyerEmail}
          onChange = {(e) => updateBuyerEmail(e.target.value)}
        />
      </InputGroup>

          <InputGroup className="center-block centerHeader">
            <InputGroup.Text>Passenger same as Buyer?<FormCheck className="ml-1" onChange = {(e)=>setPassengerInfo(e)} checked={forSelf} type="checkbox" aria-label="..." /></InputGroup.Text>
          </InputGroup>
      
      <InputGroup>
        <InputGroup.Text>Passenger (First Name):</InputGroup.Text>
        <FormControl as = "input" 
          type = "text"
          value = {recipientFirst}
          disabled = {forSelf}
          onChange = {(e)=>{setRecipientFirst(e.target.value)}}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Passenger (Last Name):</InputGroup.Text>
        <FormControl as = "input" 
          type = "text"
          value = {recipientLast}
          disabled = {forSelf}
          onChange = {(e)=>{setRecipientLast(e.target.value)}}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Passenger (Email):</InputGroup.Text>
        <FormControl as = "input" 
          type = "text"
          value = {recipientEmail}
          disabled = {forSelf}
          onChange = {(e)=>{setRecipientEmail(e.target.value)}}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Note:</InputGroup.Text>
        <FormControl as = "input" 
          type = "text"
          value = {note}
          onChange = {(e)=>{setNote(e.target.value)}}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Serial Number:</InputGroup.Text>
        <FormControl as = "input" 
          type = "text"
          value = {serialNum}
          onChange = {(e)=>{setSerialNum(e.target.value)}}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Purchase Date:</InputGroup.Text>
        <FormControl
          autoFocus
          type="date"
          value={date}
          disabled={date === ""}
          onChange={e => setDate(e.target.value)}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup.Text>Expiration Date:</InputGroup.Text>
        <FormControl
          autoFocus
          type="date"
          value={staleDate}
          disabled={staleDate === ""}
          onChange={e => updateStaleOffset(e.target.value)}
        />
      </InputGroup>
      { (introType==="" || recipientFirst==="" || recipientLast==="") ?
        <h4>Set launch type, passenger first and last name in order to define an intro</h4>
      :
        <Button size="lg" onClick={() => submitChanges()}>Submit</Button>
      }
    </main>
  );
}