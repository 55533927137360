import React, {useState, useEffect, useCallback, useMemo} from "react";
import { Alert, Spinner, FormGroup, FormControl, InputGroup, Button, ButtonGroup, ListGroup, Row, Col} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import LoaderButton from "../components/LoaderButton";
import {returnPilot, returnFlight, RequestType, apiRequest, getGroundStatus } from "../libs/databaseAccess";
import "./Ground.css";
import { DateTime } from "luxon";
import { NotificationStatus, useNotificationContext } from "../libs/notificationLib";
import ReleaseHeightModal from "../components/ReleaseHeightModal";

export default function Ground() {
  /* TODO: Add tow plane tracking - strategy:
      - Treat tows as flights, assign aircraft and pilot - these become the choosable tow services
      - UI allows assigning or updating tow pilot to tow plane, this closes open tow flights if present and creates new open tow flight
      - Open tow flights are available for glider launch
      - Glider launch also launches the associated tow - tow becomes unavailable to launch
      - When Tows are landed they automatically open a new tow flight, same plane, same pilot
  */

  const actionTypes = {
    Landing:"Landing", 
    Launch:"Launch",
    UndoLaunch:"UndoLaunch",
    UndoLanding:"UndoLanding",
    CancelRequest: "CancelRequest",
    Cancel:"Cancel",
  } 

  const { sendAlert } = useNotificationContext();
  const { userID, club, isAuthenticated, clubTimeZone } = useAppContext();

  // Form variables
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [launchFlight, setLaunchFlight] = useState("");
  const [launchFlightName, setLaunchFlightName] = useState("<None>");
  const [actionType, setActionType] = useState(actionTypes.Launch);
  const [timeDelay, setTimeDelay] = useState(0);
  const [sliderPosition, setSliderPosition] = useState(0);
  const [eventTime, setEventTime] = useState(0);
  const [launchRecord, setLaunchRecord] = useState(null);
  
  const [tugs, setTugs] = useState([]);
  const [tug, setTug] = useState("?");
  //const [towPilots, setTowPilots] = useState([]);
  const [allPilots, setAllPilots] = useState({});
  const [towPilot, setTowPilot] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [waitingFlights, setWaitingFlights] = useState([]);
  const [activeFlights, setActiveFlights] = useState([]);
  const [landedFlights, setLandedFlights] = useState([]);
  const [loadingCancel, setLoadingCancel] = useState(new Set())

  const [showReleaseHeightModal, setShowReleaseHeightModal] = useState(false)
  const [releaseHeightFlight, setReleaseHeightFlight] = useState({})

  const [errorMsg, setErrorMsg] = useState("")
  const navigate = useNavigate();

  //Per render code

  // Return formatted time difference between launch time and landing time
  // But only if a launch time has been saved
  let elapsedTime = 0;
  let hours = 0;
  if (eventTime) {
    elapsedTime = (eventTime - launchRecord)/60/1000;
    if (elapsedTime >= 60) { 
      hours = Math.floor(elapsedTime / 60);
      elapsedTime -= hours*60;
    };
  };
  let elapsedTimeString = parseInt(elapsedTime)+"M";
  if ((elapsedTime < 10) && (hours > 0)) {
    elapsedTimeString = "0"+elapsedTimeString;
  }
  if (hours > 0) {
    elapsedTimeString = parseInt(hours)+"H"+elapsedTimeString
  }

  // Methods
  const millisToTimeString = useCallback((millis) => {
    if(millis == null) return "Unknown"
    const date = DateTime.fromMillis(millis, {zone: clubTimeZone ?? 'utc'})
    return date.toLocaleString(DateTime.TIME_WITH_SECONDS)
  }, [clubTimeZone])

  const getClubDateString = useCallback(() => {
    return DateTime.fromObject({}, {zone: clubTimeZone}).toISODate()
  }, [clubTimeZone])

  const getFlightRecords = useCallback(async (getPilots=false) => {
    const localDateString = getClubDateString()
    const result = await getGroundStatus(club, localDateString, getPilots)
    if(result.statusCode !== 200){
      setErrorMsg(`Could not update flights / tugs (${result.statusCode})`)
      setPageLoading(false)
      setIsLoading(false)
      return
    }
    const todaysFlights = result.body.flights
    const tugs = result.body.tugs

    // Filter for flights still in the air and sort by launch time
    const activeFlights = todaysFlights.filter(a => (a.acceptedAt || a.launchedAt) && !a.landedAt && !a.cancelledAt)
                                      .sort((a,b) => (functionalStart(a) - functionalStart(b)));

    // Filter for flights awaiting launch and sort by request time
    const waitingFlights = todaysFlights.filter(a => !a.acceptedAt && !a.launchedAt && !a.cancelledAt)
                                      .sort((a,b) => (functionalStart(a) - functionalStart(b)));

    // Filter for landed flights and sort by reverse chronological
    const landedFlights = todaysFlights.filter(a => a.landedAt && !a.cancelledAt)
                                      .sort((a,b) => (b.landedAt - a.landedAt));

    // Reload and sort the Tugs in case the pilots were changed
    const sortedTugs = tugs.filter(a => a.pilot).sort((a, b) => ((a.SK).localeCompare(b.SK))).map((tug) => {
      return({
        "SK": tug.SK,
        "pilot": tug.pilot,
        "pilotName": tug.pilotName,
      })
    });

    // Initialize if necessary
    if (tug === "?" && sortedTugs.length > 0) {
      setTug(sortedTugs[0].SK);
      setTowPilot(sortedTugs[0].pilot);
    }
                                
    // Sort data for display and save
    setWaitingFlights(waitingFlights);
    setActiveFlights(activeFlights);
    setLandedFlights(landedFlights);
    setTugs(sortedTugs);
    
    // On refresh check if previous selection has been removed by someone else, and reset as necessary
    const flights = (actionType === actionTypes.Launch) ? waitingFlights : activeFlights;
    //if ((flights.length === 1)) { // If there is only one available flight, preselect it if not already selected
    //  setLaunchFlight(flights[0].SK);
    //  setLaunchFlightName(flights[0].glider); 
    //} else {  // This is critical to preventing an update loop, Make sure only one setLaunchFlight can be called per update
    const flight = returnFlight(club, launchFlight, flights, localDateString);
    if (!flight && launchFlight !== tug) {
      setLaunchFlight("");
      setLaunchFlightName("<None>");
    }

    if(getPilots) {
      // Pull club specific info from DynamoDB
      const pilots = result.body.pilots;
      setAllPilots(pilots.reduce((map, p) => {
        map[p.SK] = p;
        return map
      }, {}))
      // Build a tow pilot list
      //const towPilots = pilots.filter(pilot => pilot.isTowPilot).sort((a, b) => (a.first+a.last).localeCompare(b.first+b.last));
      //setTowPilots(towPilots);
      // TODO: Record the active tow pilot for a tug such that that pilot is the default until the active pilot changes

      // Get current pilot info
      const pilot = returnPilot(club, userID, pilots)
      if (typeof pilot !== 'undefined'){
        if ("first" in pilot) { 
          setFirst(pilot.first);
        }
        if ("last" in pilot) { 
          setLast(pilot.last);
        }
      }  
    }
    setErrorMsg("")
    setPageLoading(false)
    setIsLoading(false)
  }, [club, actionType, actionTypes.Launch, launchFlight, tug, userID, getClubDateString])

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated || club === "") {
        return false;
      }
  
      try {
        await getFlightRecords(true)
      } catch (e) {
        onError(e);
      }
      
      return true
    }

    if (onLoad()) {
      // Refresh flight status
      const interval = setInterval(getFlightRecords, 10000);
      return () => {
        clearInterval(interval);
      };
    }

  }, [isAuthenticated, club, getFlightRecords]);

  // Initialize time
  useEffect(() => {
    const date = DateTime.fromObject({}, {zone: clubTimeZone})
    setEventTime(date.toMillis())
  }, [clubTimeZone])

  function functionalStart(flight){
    if (flight.acceptedAt && flight.launchedAt) {
      return ((flight.acceptedAt > flight.launchedAt) ? flight.acceptedAt : flight.launchedAt);
    }
    if (flight.acceptedAt) {
      return (flight.acceptedAt);
    }
    if (flight.launchedAt) {
      return (flight.launchedAt);
    }

  }

  // Effect to update displayed time
  useEffect(() => {
    const timer = () => {
      const dateStruct = new Date();        // Returns UTC time (what will be stored)
      setEventTime(dateStruct.getTime() - (!isNaN(parseInt(timeDelay)) ? parseInt(timeDelay) : 0)*60*1000); 
    }
    const id = setInterval(timer, 10000);
    return () => {
      clearInterval(id);
    };
  },[eventTime, launchRecord, timeDelay])

  function createFlightBody(id, action) {
    // Build up body for API Call
    // TODO: Add tug info to body (and update back end)
    const transactionTime = new Date();
    const body = {
      date: getClubDateString(),
      club: club,
      id: id,
      action: action,
      time: eventTime,
      by: userID,
      enteredTime: transactionTime.getTime(),
    };
    if (action === actionTypes.Launch) {
      body.towPilot = towPilot;
      body.tug =  tug;
    }
    return body
  }

  async function submitUpdate() {
    setIsLoading(true);
    const bodyContent = createFlightBody(launchFlight, actionType);
    const result = await apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-flight/", options: {"body": bodyContent}});
    if(result.statusCode !== 200) {
      sendAlert({
        status: NotificationStatus.Danger,
        message: 'No action taken - ' + result.body.message
      })
      setIsLoading(false);
      return
    }
    setLaunchFlight(null);
    setLaunchFlightName("<None>");
    setTimeDelay(0);
    setSliderPosition(0);
    const dateStruct = new Date();
    setEventTime(dateStruct.getTime());
    getFlightRecords() // sets isLoading to false when request succeeds
  }

  const setCanceling = (id) => {
    setLoadingCancel((s) => {
      s.add(id);
      return s;
    })
  }
  
  const setNotCancelling = (id) => {
    setLoadingCancel((s) => {
      s.delete(id);
      return s;
    })
  }

  async function submitCancelLaunch(id) {
    const flight = returnFlight(club, id, activeFlights, getClubDateString());
    setCanceling(id)
    if (window.confirm(`Undo launch of ${flight.glider} at ${millisToTimeString(flight.launchedAt)}?`)) {
      const bodyContent = createFlightBody(id, actionTypes.UndoLaunch);
      // Update the flight information
      const result = await apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-flight/", options: {"body": bodyContent}});
      if(result.statusCode !== 200) {
        sendAlert({
          status: NotificationStatus.Danger,
          message: `Failed to cancel launch`
        })
        setNotCancelling(id)
        return
      }
      getFlightRecords()
    }
    setNotCancelling(id)
  }

  async function submitCancelLanding(id) {
    const flight = returnFlight(club, id, landedFlights, getClubDateString());
    setCanceling(id)
    if (window.confirm(`Undo landing of ${flight.glider} at ${millisToTimeString(flight.landedAt)}?`)) {
      const bodyContent = createFlightBody(id, actionTypes.UndoLanding);
      // Update the flight information
      const result = await apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-flight/", options: {"body": bodyContent}});
      if(result.statusCode !== 200) {
        sendAlert({
          status: NotificationStatus.Danger,
          message: `Failed to cancel landing`
        })
        setNotCancelling(id)
        return
      }
      getFlightRecords()
    }
    setNotCancelling(id)
  }

  async function submitCancelRequest(id) {
    const bodyContent = createFlightBody(id, actionTypes.CancelRequest);
    // Update the flight informatin
    await apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-flight/", options: {"body": bodyContent}});
    getFlightRecords()
  }

  async function submitCancelIntro(id){
    const introUpdateData = {
      "flightRef":'',
    }
    const introUpdateBody = {
      "club":club,
      "type":"INTRO",
      "id":id,
      "data":introUpdateData,
    }
    await apiRequest(RequestType.POST, {apiName: "flightline", path: "/update-item/", options: {
      "body": introUpdateBody,
    }});
  }

  function setLaunchValues(event){
    // Record the relevant information about the selected flight
    // The flight id, glider id
    const id = event.target.value;
    let flightName = null;
    let flightLaunch = null;
    let flightId = null

    if (id !== tug){
      const flights = (actionType === actionTypes.Launch) ? waitingFlights : activeFlights;
      const flight = returnFlight(club, id, flights, getClubDateString());
      flightName = flight.glider;
      flightLaunch = flight.launchedAt;
      flightId = id;
    } else {
      flightName = tug;
      flightId = tug;
    }

    setLaunchFlight(flightId);
    setLaunchFlightName(flightName);
    if (actionType === actionTypes.Landing) {
      setLaunchRecord(flightLaunch);        // Use to report flight duration
    } else {
      setLaunchRecord(null);
    }

  }

  function updateTime(delay){
    const effectiveDelay = !isNaN(parseInt(delay)) ? parseInt(delay) : 0;
    const dateStruct = new Date();
    const launchTime = dateStruct.getTime() - (effectiveDelay)*60*1000;
    setTimeDelay(delay);
    setEventTime(launchTime);
    const position = -72.8054*Math.log(+delay+0.51);    // See updateSlider for explanation
    setSliderPosition(!isNaN(position) ? position : 0);
  }

  function updateSlider(position) {
    // Updates the slider position
    // Manage separately to allow updateTime to handle blank entries, and to have smooth (non-integer) scrolling
    // Map linear position to exponential, fitting slider minimum to 960 minutes (16 hours)
    // e.g.: Slider position = [0,500] -> delay = (e^(ln(961)/500))^position = 1.01383^position
    // The reverse conversion is position = ln(delay)/ln(961/500) = 72.8054*ln(delay)
    // With some rounds and offsets pushed in to make integers and enable slide to 0.
    // Math.trunc is apparently not fully supported by IE, so using round with an offset instead
    const delay = (Math.pow(1.01383,-position)-0.51);
    const effectiveDelay = !isNaN(parseInt(delay)) ? parseInt(delay) : 0;
    const dateStruct = new Date();
    const launchTime = dateStruct.getTime() - (effectiveDelay)*60*1000;
    setTimeDelay(Math.round(delay-0.51));
    setEventTime(launchTime);
    setSliderPosition(-72.8054*Math.log(delay+0.51));
  }

  function updateAction(action){
    setActionType(action);
    const dateStruct = new Date();
    const launchTime = dateStruct.getTime() - (!isNaN(parseInt(timeDelay)) ? parseInt(timeDelay) : 0)*60*1000;
    setEventTime(launchTime);
  }

  const eventTimeString = useMemo(() => millisToTimeString(eventTime), [eventTime, millisToTimeString])

  function cancelRequest() {
    const flight = returnFlight(club, launchFlight, waitingFlights, getClubDateString());

    if (window.confirm(`Confirm remove ${flight.first} ${flight.last} in ${flight.glider} from flight line.`)) {
      submitCancelRequest(flight.SK);
      if(flight.flyWith === 'Intro' && 'introRef' in flight){
        submitCancelIntro(flight.introRef);
      }
    }
  }

  function setLaunchReleaseHeight(flight) {
    setShowReleaseHeightModal(true)
    setReleaseHeightFlight(flight)
  }

  function closeReleaseHeightModal() {
    setShowReleaseHeightModal(false)
    setReleaseHeightFlight({})
  }

  function updateTugAndPilot(tugChoice) {
    setTug(tugChoice);
    const tugInfo = tugs.filter(a => a.SK === tugChoice)
    setTowPilot(tugInfo[0].pilot);
  }

  function renderTugs(tugs) {
    return (
      <div className="tugs">
        <h4 className = "centerHeader">Launched By:</h4>
        <ButtonGroup className="mb-3 flex-wrap">
        <Button key="new" value="new" variant="outline-primary" onClick = {() => navigate("/fuel")}>Check in Tow Pilot</Button>
          { tugs.map((item) => (
            <Button variant="outline-secondary" key={item.SK} value={item.SK} onClick = {(e) => updateTugAndPilot(e.target.value)} 
              active={(item.SK === tug)}>
              {item.SK} {item.pilotName}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    );
  }


  function renderFlightsWaiting(flights) {
    return (
      <div className="flights">
        <h4 className = "centerHeader">Next Flight:</h4>
        <ButtonGroup className="mb-3 flex-wrap">
          { flights.map((item) => (
          <Button variant="outline-secondary" key={item.SK} value={item.SK} onClick = {(e) => setLaunchValues(e)} 
            active={(item.SK === launchFlight)}>
            {item.glider + ((flights.length < 10) ? (", " + item.first+" "+item.last.slice(0,1)) : "")}
          </Button>
          ))
          }
        </ButtonGroup>
      </div>
    );
  }

  function renderFlightsAirborn(flights) {
    return (
      <div className="flights">
        <h4 className = "centerHeader">Choose Glider to Land</h4>
        <ButtonGroup className="mb-3 flex-wrap">
          { flights.map((item) => (
          <Button variant="outline-secondary" key={item.SK} value={item.SK} onClick = {(e) => setLaunchValues(e)} 
            active={(item.SK === launchFlight)}> 
            {item.glider + ", " + item.first+" "+item.last.slice(0,1)}
          </Button>
          ))
          }
        </ButtonGroup>
      </div>
    );
  }

  function renderCancelButtons(flight, page) {
    const cancelFn = page === actionTypes.Launch ? submitCancelLaunch : submitCancelLanding
    const undoSuffix = page === actionType.Landing ? `landed ${millisToTimeString(flight.landedAt)}` : `launched ${millisToTimeString(flight.launchedAt)}`
    const cancelButton = (
      <LoaderButton
        variant="danger"
        key={flight.SK}
        value={flight.SK}
        onClick = {(e) => cancelFn(e.target.value)}
        active={flight.SK === launchFlight}
        isLoading={loadingCancel.has(flight.SK)}
        disabled={loadingCancel.has(flight.SK)}
        className="w-100"
      >
        {`Undo ${flight.first} ${flight.last.slice(0,1)} in ${flight.glider} ${undoSuffix}`}
      </LoaderButton>
    )

    const setReleaseHeightButton = (
      <Button
        value={flight.SK}
        variant="warning"
        disabled={loadingCancel.has(flight.SK)}
        onClick = {() => setLaunchReleaseHeight(flight)}
        className="w-100"
      >
        Release Height
      </Button>
    )

    if(flight.releaseHeight === 0) {
      return (
        <div key={flight.SK} className="undo-btn-container">
          {cancelButton}
          {setReleaseHeightButton}
        </div>
      )
    } else {
      return cancelButton
    }
  }

  function renderCancellableLaunches(flights) {
    return (
      <div className="flights">
        <ListGroup className="gap-2">
          { (flights.length === 0) ? <p>No launches to undo</p> : 
          <>
            { flights.map((flight, i) => renderCancelButtons(flight, actionTypes.Launch)) }
          </>
          }
        </ListGroup>
      </div>
    );
  }
  
  function renderCancellableLandings(flights) {
    return (
      <div className="flights">
        <ListGroup className="gap-2">
          { (flights.length === 0) ? <p>No landings to undo</p> : 
          <>
            { flights.map((flight) => renderCancelButtons(flight, actionTypes.Landing))}
          </>
          }
        </ListGroup>
      </div>
    );
  }

  function renderLoading() {
    return (
      <Spinner className="mt-4" animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
  }

  function renderActivities() {
    return (
      <main className="d-flex flex-column gap-3 pt-3">
        { ((first !== "") || (last !== "")) ?
          <h4 className="my-0">Line Manager for {first} {last}</h4> :
          <h4 className="my-0">Line Management</h4>
        }
        <hr className="my-1"/>
        <Row className="justify-content-between">
        <Col xs="3">
          <div className="d-grid">
            <Button variant="outline-secondary" type="button" disabled={pageLoading} active={actionType === actionTypes.Launch} 
              onClick = {() => updateAction(actionTypes.Launch)}>
              <h4>Launch</h4>
            </Button>
          </div>
        </Col>
        <Col xs="3">
          <div className="d-grid">
            <Button variant="outline-secondary" type="button" disabled={pageLoading} active={actionType === actionTypes.Landing} 
              onClick = {() => updateAction(actionTypes.Landing)}>
              <h4>Land</h4>
            </Button>
          </div>
        </Col>
        </Row>
        <hr className="my-1"/>
        { actionType === actionTypes.Landing &&
          <div>
            {(renderFlightsAirborn((activeFlights)))}
          </div>
        }
        { (actionType === actionTypes.Launch || actionType === actionTypes.Cancel) &&
            <div>
              {(renderFlightsWaiting((waitingFlights)))}  
              {(renderTugs((tugs)))}  
              <hr className="my-1"/>
            </div>
        }
        { actionType !== "" && eventTime &&
          <>
            <input type="range" className="w-100" id="points" name="points" min={-500} max="0" 
                  onChange={e => updateSlider(e.target.value)}
                  value = {sliderPosition}
            />
            <FormGroup controlId="timeDelay" size="lg">
              <InputGroup>
              {<InputGroup.Text id="basic-addon1">Happened </InputGroup.Text>}
                <FormControl
                  type="number"
                  inputMode="numeric"
                  as="input"
                  value={timeDelay}
                  onChange={e => updateTime(e.target.value)}
                />
              <InputGroup.Text>Minutes ago </InputGroup.Text>
              </InputGroup>
            </FormGroup>
            <div className="btn-container">
              <LoaderButton
                type="submit"
                variant="success"
                isLoading={isLoading} 
                disabled={(actionType === actionTypes.Launch && tug === "?") || !launchFlight}
                onClick= {() => submitUpdate()}
                className="fs-4 w-100"
              >
                {(actionType === actionTypes.Launch) && 
                  `Launch ${launchFlightName} at ${eventTimeString} with ${tug}`
                }
                { (actionType === actionTypes.Landing) && (!launchRecord) &&
                  `Land ${launchFlightName} at ${eventTimeString}`
                }
                { (actionType === actionTypes.Landing) && (launchRecord) &&
                  `Land ${launchFlightName} at ${eventTimeString} (${elapsedTimeString})`
                }
              </LoaderButton>                        
              { (actionType === actionTypes.Launch || actionType === actionTypes.Landing) &&
                <LoaderButton
                  type="submit"
                  variant="danger"
                  isLoading={isLoading} 
                  disabled={!launchFlight}
                  onClick= {() => actionType === actionTypes.Launch ? cancelRequest() : submitCancelLaunch(launchFlight)}
                >
                  {actionType === actionTypes.Launch ? "Remove from flightline" : "Undo launch"}
                </LoaderButton>  
              }
            </div>
          </>
        }
        <hr className="my-1"/>
        <ReleaseHeightModal 
          showModal={showReleaseHeightModal}
          onSuccess={getFlightRecords}
          allPilots={allPilots}
          closeModal={closeReleaseHeightModal}
          flight={releaseHeightFlight}   
        />
        { actionType === actionTypes.Launch &&
          <div>
            <h4>Flights in Progress:</h4>
            {(renderCancellableLaunches((activeFlights)))}
          </div>
        }  
        { actionType === actionTypes.Landing &&
          <div>
            <h4>Landed Flights:</h4>
            {(renderCancellableLandings((landedFlights)))}
          </div>
        }
        <div className="position-fixed bottom-0 start-50 translate-middle w-100 px-3">
          <Alert show={errorMsg.length > 0} className="w-100 mb-0" variant="danger" dismissible onClose={() => setErrorMsg("")}>
            {errorMsg}
          </Alert>
        </div>
      </main>
    );
  }

  return (
    <div>
      {(isAuthenticated && !pageLoading) ? renderActivities() : renderLoading()}
    </div>
  );
}
